<template>
  <button type="button">{{$t('button.apply')}}</button>
</template>

<script>
export default {
  name: 'BtnRegist',
  props: ['func']
}
</script>

<style scoped>
  button{
    width: 100%;
    z-index: 1;
    bottom: 0;
    font-size: 1.5em;
    color: #fff;
    background: #353535;
    border: 1px solid #353535;
    padding:5px;
    cursor: pointer;
  }
  button:hover{
    background:#fff;
    color: #353535;
  }
</style>
