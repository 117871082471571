<template>
   <section class="min-w1600">
      <div class="searchwrap">
         <section class="page-container">
            <div class="page-contents">
             <article class="page-contents-wrap">
                <div class="sub-title">
                 <h3>{{ $t('common.userAllSet') }}</h3>
                 <button type="button" class="btn-layout" @click="save_siteMember">{{ $t('button.save') }}</button>
                </div>
                <div class="page-content s-content-wrap wrapper-contents">
                 <div class="s-content">
                    <div class="content item">
                      <span class="itemtxt">{{ $t('user.isLogin') }}</span>
                      <ui-toggle :textType="'default'" :checked="options_siteMember.loginYn" @setYn="setOptionsYn(options_siteMember, 'loginYn')" />
                    </div>
                 </div>
                 <div class="s-content">
                    <div class="content item">
                      <span class="itemtxt">{{ $t('user.isBetting2') }}</span>
                      <ui-toggle :textType="'default'" :checked="options_siteMember.betYn" @setYn="setOptionsYn(options_siteMember, 'betYn')" />
                    </div>
                 </div>
                 <div class="s-content">
                    <div class="content item">
                      <span class="itemtxt">{{ $t('common.actLockSet') }}</span>
                      <ui-toggle :textType="'default'" :checked="options_siteMember.memLockYn" @setYn="setOptionsYn(options_siteMember, 'memLockYn')" />
                    </div>
                 </div>
                 <div class="s-content">
                    <div class="content item">
                      <span class="itemtxt">{{ $t('common.actReqYN') }}</span>
                      <ui-toggle :textType="'default'" :checked="options_siteMember.accInfoRequestYn" @setYn="setOptionsYn(options_siteMember, 'accInfoRequestYn')" />
                    </div>
                 </div>
                 <div class="s-content">
                    <div class="content item">
                      <span class="itemtxt">{{ $t('common.companyCode') }}</span>
                      <span class="">Qa2er8k96</span>
                    </div>
                 </div>
                 <div class="s-content">
                    <div class="content item">
                      <span class="itemtxt">{{ $t('common.changePartnerYN') }}</span>
                      <ui-toggle :textType="'default'" :checked="options_siteMember.partnerLevelChgYn" @setYn="setOptionsYn(options_siteMember, 'partnerLevelChgYn')" />
                    </div>
                 </div>
                 <div class="s-content">
                    <div class="content item">
                      <span class="itemtxt">{{ $t('common.recommendYnOn') }}</span>
                      <ui-toggle :textType="'default'" :checked="options_siteMember.recommendYn" @setYn="setOptionsYn(options_siteMember, 'recommendYn')" />
                    </div>
                 </div>
                 <div class="s-content">
                    <div class="content item">
                      <span class="itemtxt">{{ $t('user.userRecommend') }}</span>
                      <ui-toggle :textType="'default'"  :checked="options_siteMember.userRecommenderChgYn" @setYn="setOptionsYn(options_siteMember, 'userRecommenderChgYn')"/>
                    </div>
                 </div>
                </div>
             </article>
             <article class="page-contents-wrap">
                <div class="sub-title">
                    <h3>{{ $t('common.siteBasicSet') }}</h3>
                    <button type="button" class="btn-layout" @click="save_basic">{{ $t('button.save') }}</button>
                </div>
                <div class="page-content s-content-wrap wrapper-contents">
                 <div class="s-content">
                    <div class="content item">
                      <span class="itemtxt">{{ $t('common.siteYN') }}</span>
                      <ui-toggle :textType="'default'" :checked="options_basic.siteStatusYn" @setYn="setOptionsYn(options_basic, 'siteStatusYn')" />
                    </div>
                 </div>
                 <div class="s-content">
                    <div class="content item">
                      <span class="itemtxt">{{ $t('common.joinYN') }}</span>
                      <ui-toggle :textType="'default'" :checked="options_basic.signupYn" @setYn="setOptionsYn(options_basic, 'signupYn')" />
                    </div>
                 </div>
                 <div class="s-content">
                    <div class="content item">
                      <span class="itemtxt">{{ $t('common.boardYN') }}</span>
                      <ui-toggle :textType="'default'" :checked="options_basic.boardYn" @setYn="setOptionsYn(options_basic, 'boardYn')" />
                    </div>
                 </div>
                 <div class="s-content">
                    <div class="content item dp-flex-c f-as">
                      <span class="itemtxt">{{ $t('common.scoreYN') }}</span>
                      <div class="dot-content">
                        <select>
                          <option value="1" selected  >1 ({{ $t('common.notdot') }})</option>
                          <option value="10">0.1 ({{ $t('common.dot1') }})</option>
                          <option value="100">0.01 ({{ $t('common.dot2') }})</option>
                          <option value="1000">0.001 ({{ $t('common.dot3') }})</option>
                        </select>
                      </div>
                    </div>
                 </div>
                </div>

                <div class="sub-title mt-52">
                    <h3>{{ $t('common.siteSet') }}</h3>
                  <button type="button" class="btn-layout" @click="onChangeSiteCheck">{{ $t('button.save') }}</button>
                </div>
                <div class="page-content s-content-wrap wrapper-contents">
                  <div class="s-content">
                    <div class="content item">
                      <span class="itemtxt">{{ $t('common.siteSet') }}</span>
                      <ui-toggle :textType="'default'" :checked="options_site.siteCheckYn" @setYn="setOptionsYn(options_site, 'siteCheckYn')" />
                    </div>
                 </div>
                </div>

             </article>
             <article class="page-contents-wrap">
                <div class="sub-title">
                    <h3>{{ $t('common.inoutPointSet') }}</h3>
                    <button type="button" class="btn-layout" @click="save_cash">{{ $t('button.save') }}</button>
                </div>
                <div class="page-content">
                 <ul class="tab-wrap">
                    <li class="tab-item" :class="{ active: currentTab1 === 'cashin' }" @click="onChangeType('cashin')">{{ $t('table.head.deposit') }}</li>
                    <li class="tab-item" :class="{ active: currentTab1 === 'cashout' }" @click="onChangeType('cashout')">{{ $t('table.head.withdraw') }}</li>
                    <li class="tab-item" :class="{ active: currentTab1 === 'point' }" @click="onChangeType('point')">{{ $t('table.body.point') }}</li>
                 </ul>
                 <div class="tabMenu" v-if="currentTab1 === 'cashin'"> <!--탭메뉴 입금-->
                    <div class="item-wrap">
                      <div class="item-title">{{ $t('common.inLimit') }}</div>
                      <div class="item-content">
                        <input type="text" v-model="options_cash.cashInAmtLimit"/>
                      </div>
                    </div>
                    <div class="item-wrap">
                      <div class="item-title">{{ $t('common.inApplyMin') }}</div>
                      <div class="item-content">
                        <input type="text" v-model="options_cash.cashInAmtMin" />
                      </div>
                    </div>
                    <div class="item-wrap">
                      <div class="item-title">{{ $t('common.inApplyUnit') }}</div>
                      <div class="item-content">
                        <input type="text" v-model="options_cash.cashInAmtUnit" />
                      </div>
                    </div>
                    <div class="item-wrap">
                      <div class="item-title">{{ $t('common.inApplyReSet') }}</div>
                      <div class="item-content">
                        <input type="text" v-model="options_cash.cashInWaitTime" />
                      </div>
                    </div>
                 </div>
                 <div class="tabMenu" v-if="currentTab1 === 'cashout'"> <!--탭메뉴 출금-->
                    <div class="item-wrap">
                      <div class="item-title">{{ $t('common.outLimit') }}</div>
                      <div class="item-content">
                        <input type="text" v-model="options_cash.cashOutAmtLimit"/>
                      </div>
                    </div>
                    <div class="item-wrap">
                      <div class="item-title">{{ $t('common.outApplyMin') }}</div>
                      <div class="item-content">
                        <input type="text" v-model="options_cash.cashOutAmtMin" />
                      </div>
                    </div>
                    <div class="item-wrap">
                      <div class="item-title">{{ $t('common.outApplyUnit') }}</div>
                      <div class="item-content">
                        <input type="text" v-model="options_cash.cashOutAmtUnit" />
                      </div>
                    </div>
                    <div class="item-wrap">
                      <div class="item-title">{{ $t('common.outApplyReSet') }}</div>
                      <div class="item-content">
                        <input type="text" v-model="options_cash.cashOutWaitTime" />
                      </div>
                    </div>
                 </div>
                 <div class="tabMenu" v-if="currentTab1 === 'point'">
                    <div class="item-wrap">
                      <div class="item-title">{{ $t('common.pointExchLimit') }}</div>
                      <div class="item-content">
                        <input type="text" v-model="options_cash.pointChangeAmtLimit" />
                      </div>
                    </div>
                    <div class="item-wrap">
                      <div class="item-title">{{ $t('common.pointExchType') }}</div>
                      <div class="item-content">
                        <select class="reqMember" v-model="options_cash.pointChangeType">
                          <option value="M">{{ $t('searchArea.manual') }}</option>
                          <option value="A">{{ $t('searchArea.auto') }}</option>
                        </select>
                      </div>
                    </div>
                    <div class="item-wrap">
                      <div class="item-title">{{ $t('common.pointExchUnit') }}</div>
                      <div class="item-content">
                        <input type="text" v-model="options_cash.pointChangeAmtUnit" />
                      </div>
                    </div>
                    <!-- <div class="item-wrap">
                      <div class="item-title">{{ $t('common.pointCondition') }}</div>
                      <ui-toggle :textType="'default'" :checked="options_cash.pointStatusYn" @setYn="setOptionsYn(options_cash, 'pointStatusYn')" />
                    </div> -->
                 </div>
                </div>
                <div class="sub-title mt-14">
                  <h3>{{ $t('common.moneySet') }}</h3>
                  <button type="button" class="btn-layout" @click="onSaveMaxWinAmt">{{ $t('button.save') }}</button>
                </div>
                <div class="page-content s-content-wrap wrapper-contents">
                  <div class="item-content">
                    <div class="item-wrap">
                      <div class="item-title">{{ $t('table.head.amount') }}</div>
                      <div class="item-content">
                        <input type="text" v-model="options_cash.maxWinAmt"/>
                      </div>
                    </div>
                  </div>
                </div>
             </article>
             <article class="page-contents-wrap">
                <div class="sub-title">
                    <h3>{{ $t('common.siteEtcSet') }} |</h3>
                    <button type="button" class="btn-layout" @click="save_etc">{{ $t('button.save') }}</button>
                </div>
                <div class="page-content">
                    <div class="item-wrap">
                      <div class="item-title">{{ $t('common.limitRecom') }}</div>
                      <div class="item-content">
                        <input type="text" v-model="options_etc.recommend_limit" />
                      </div>
                    </div>
                    <div class="item-wrap">
                      <div class="item-title">{{ $t('common.autoLogoutM') }}</div>
                      <div class="item-content">
                        <input type="text" v-model="options_etc.logoutTimeLimit" />
                      </div>
                    </div>
                    <div class="item-wrap d-flex-sb-c">
                      <div class="item-title">{{ $t('common.autoLogoutSet') }}</div>
                      <div class="item-content">
                        <ui-toggle :textType="'default'" :checked="options_etc.adminAutoLogoutYn" @setYn="setOptionsYn(options_etc, 'adminAutoLogoutYn')" />
                      </div>
                    </div>
                    <div class="item-wrap d-flex-sb-c">
                      <div class="item-title">{{ $t('common.reAlertYN') }}</div>
                      <div class="item-content">
                        <ui-toggle :textType="'default'" :checked="options_etc.alarmRepeatYn" @setYn="setOptionsYn(options_etc, 'alarmRepeatYn')" />
                      </div>
                    </div>
                </div>
             </article>
             <article class="page-contents-wrap">
                <div class="sub-title">
                    <h3>{{ $t('common.siteEtcSet') }} ||</h3>
                    <button type="button" class="btn-layout" @click="save_etc">{{ $t('button.save') }}</button>
                </div>
                <div class="page-content">
                 <ul class="tab-wrap">
                    <li class="tab-item" :class="{ active: currentTabSNS === 'sns1' }" @click="onChangeTypeSNS('sns1')">SNS1</li>
                    <li class="tab-item" :class="{ active: currentTabSNS === 'sns2' }" @click="onChangeTypeSNS('sns2')">SNS2</li>
                    <li class="tab-item" :class="{ active: currentTabSNS === 'sns3' }" @click="onChangeTypeSNS('sns3')">SNS3</li>
                 </ul>
                 <div class="tabMenu" v-if="currentTabSNS === 'sns1'">
                    <div class="item-wrap">
                      <div class="item-title">{{ $t('common.sns1') }}</div>
                      <div class="item-content">
                        <select v-model="options_etc.sns1">
                          <option value="kakaoTalk">{{ $t('common.kakao') }}</option>
                          <option value="telegram">{{ $t('common.tele') }}</option>
                        </select>
                      </div>
                    </div>
                    <div class="item-wrap">
                      <div class="item-title">{{ $t('common.snsID1') }}</div>
                      <div class="item-content">
                        <input type="text" v-model="options_etc.sns1Id" />
                      </div>
                    </div>
                    <div class="item-wrap">
                      <div class="item-title">{{ $t('common.snsLink1') }}</div>
                      <div class="item-content">
                        <input type="text" v-model="options_etc.sns1Link" />
                      </div>
                    </div>
                 </div>
                 <div class="tabMenu" v-if="currentTabSNS === 'sns2'">
                    <div class="item-wrap">
                      <div class="item-title">{{ $t('common.sns2') }}</div>
                      <div class="item-content">
                        <select v-model="options_etc.sns2">
                          <option value="kakaoTalk">{{ $t('common.kakao') }}</option>
                          <option value="telegram">{{ $t('common.tele') }}</option>
                        </select>
                      </div>
                    </div>
                    <div class="item-wrap">
                      <div class="item-title">{{ $t('common.snsID2') }}</div>
                      <div class="item-content">
                        <input type="text" v-model="options_etc.sns2Id" />
                      </div>
                    </div>
                    <div class="item-wrap">
                      <div class="item-title">{{ $t('common.snsLink2') }}</div>
                      <div class="item-content">
                        <input type="text" v-model="options_etc.sns2Link" />
                      </div>
                    </div>
                 </div>
                 <div class="tabMenu" v-if="currentTabSNS === 'sns3'">
                    <div class="item-wrap">
                      <div class="item-title">{{ $t('common.sns3') }}</div>
                      <div class="item-content">
                        <select v-model="options_etc.sns3">
                          <option value="kakaoTalk">{{ $t('common.kakao') }}</option>
                          <option value="telegram">{{ $t('common.tele') }}</option>
                        </select>
                      </div>
                    </div>
                    <div class="item-wrap">
                      <div class="item-title">{{ $t('common.snsID3') }}</div>
                      <div class="item-content">
                        <input type="text" v-model="options_etc.sns3Id" />
                      </div>
                    </div>
                    <div class="item-wrap">
                      <div class="item-title">{{ $t('common.snsLink3') }}</div>
                      <div class="item-content">
                        <input type="text" v-model="options_etc.sns3Link" />
                      </div>
                    </div>
                 </div>
                </div>
                <div class="sub-title mt-62">
                    <h3>{{ $t('common.devSet') }}</h3>
                  <button type="button" class="btn-layout" @click="onChangeParse">{{ $t('button.save') }}</button>
                </div>
                <div class="page-content s-content-wrap wrapper-contents">
                  <div class="s-content">
                    <div class="content item">
                      <span class="itemtxt">{{ $t('common.devSet') }} ({{ $t('common.devnouse') }})</span>
                      <ui-toggle :textType="'default'" :checked="options_parse.parseUseYn" @setYn="setOptionsYn(options_parse, 'parseUseYn')" />
                    </div>
                 </div>
                </div>
             </article>
            </div>
         </section>
      </div>
   </section>
</template>

<script>
import lodash from 'lodash'
import { getSiteData } from '@/libs/auth-helper'
import { thousand } from '@/libs/utils'
import subTitle from '@/components/main/PageSubTitle'
import typing from '@/components/ui/Typing'
import uiCheck from '@/components/ui/UiCheck'
import uiToggle from '@/components/ui/UiToggle'
import BtnRegist from '@/components/ui/BtnRegist'
import { siteOption, siteOptionSave, companyDetail } from '@/api/member'
import { parseApi, settingCheckInsert, siteCheckOff } from '@/api/setting.js'
import { GAME_INFO_LIST } from '@/libs/constants.js'

export default {
  name: 'SiteOptions',
  components: {
    subTitle,
    typing,
    uiCheck,
    uiToggle,
    BtnRegist
  },
  data () {
    return {
      currentTab1: 'cashin',
      currentTabSNS: 'sns1',
      options_siteMember: {
        loginYn: false,
        betYn: false,
        recommendYn: false,
        memLockYn: false,
        accInfoRequestYn: false,
        partnerLevelChgYn: false,
        userRecommenderChgYn: false
      },
      options_basic: {
        siteStatusYn: false,
        multiLoginYn: false,
        signupYn: false,
        liveStreamYn: false,
        boardYn: false,
        userLoginAlarmYn: false,
        maxBetRateYn: false,
        cashOutRollMsgYn: false,
        liveSprotsMsgYn: false,
        specialBetMonoYn: false,
        accInfoRequestYn: false,
        callYn: false,
        casinoRollChangeYn: false
      },
      options_etc: {
        companyRecommender: '',
        smsAuthPass: '',
        recommend_limit: '0',
        adminAutoLogoutYn: false,
        logoutTimeLimit: '0',
        alarmSounds: '',
        alarmRepeatYn: false,
        sns1: '',
        sns1Id: '',
        sns1Link: '',
        sns2: '',
        sns2Id: '',
        sns2Link: '',
        sns3: '',
        sns3Id: '',
        sns3Link: '',
        supFeeRate: 0,
        insuredDownYn: false,
        insuredUpYn: false,
        insuredUpRate: 0
      },
      insuredOptions: {
        supUse: false,
        subUse: false
      },
      feeRateMy: 0,
      options_cash: {
        cashInAmtLimit: '0',
        cashOutAmtLimit: '0',
        pointChangeAmtLimit: '0',
        pointChangeType: 'M',
        cashInAmtMin: '0',
        cashOutAmtMin: '0',
        cashInAmtUnit: '0',
        cashOutAmtUnit: '0',
        pointChangeAmtUnit: '0',
        pointStatusYn: false,
        cashInWaitTime: '0',
        cashOutWaitTime: '0',
        maxWinAmt: '0'
      },
      options_bet: {
        maxBetRate: '50',
        betCancelTimeLimit_beforeGame: '0',
        betCancelTimeLimit_afterBet: '0',
        strangeBetAlarm: false
      },
      companyData: {},
      joinData: {},
      siteInfo: {},
      options_site: {
        siteCheckYn: false
      },
      options_parse: {
        parseUseYn: false
      }
    }
  },
  watch: {
    'options_cash.pointChangeAmtUnit' () {
      const parts = this.options_cash.pointChangeAmtUnit.split('.')
      const v = parts[0].replace(/\D/g, '')
      const dec = parts[1]
      const calcNum = Number(dec !== undefined ? v + '.' + dec : v)
      let n = new Intl.NumberFormat('en-EN').format(v)
      n = dec !== undefined ? n + '.' + dec : n
      this.options_cash.pointChangeAmtUnit = n
    },
    'options_cash.pointChangeAmtLimit' () {
      const parts = this.options_cash.pointChangeAmtLimit.split('.')
      const v = parts[0].replace(/\D/g, '')
      const dec = parts[1]
      const calcNum = Number(dec !== undefined ? v + '.' + dec : v)
      let n = new Intl.NumberFormat('en-EN').format(v)
      n = dec !== undefined ? n + '.' + dec : n
      this.options_cash.pointChangeAmtLimit = n
    },
    'options_cash.cashOutAmtUnit' () {
      const parts = this.options_cash.cashOutAmtUnit.split('.')
      const v = parts[0].replace(/\D/g, '')
      const dec = parts[1]
      const calcNum = Number(dec !== undefined ? v + '.' + dec : v)
      let n = new Intl.NumberFormat('en-EN').format(v)
      n = dec !== undefined ? n + '.' + dec : n
      this.options_cash.cashOutAmtUnit = n
    },
    'options_cash.cashOutAmtMin' () {
      const parts = this.options_cash.cashOutAmtMin.split('.')
      const v = parts[0].replace(/\D/g, '')
      const dec = parts[1]
      const calcNum = Number(dec !== undefined ? v + '.' + dec : v)
      let n = new Intl.NumberFormat('en-EN').format(v)
      n = dec !== undefined ? n + '.' + dec : n
      this.options_cash.cashOutAmtMin = n
    },
    'options_cash.cashOutAmtLimit' () {
      const parts = this.options_cash.cashOutAmtLimit.split('.')
      const v = parts[0].replace(/\D/g, '')
      const dec = parts[1]
      const calcNum = Number(dec !== undefined ? v + '.' + dec : v)
      let n = new Intl.NumberFormat('en-EN').format(v)
      n = dec !== undefined ? n + '.' + dec : n
      this.options_cash.cashOutAmtLimit = n
    },
    'options_cash.cashInAmtUnit' () {
      const parts = this.options_cash.cashInAmtUnit.split('.')
      const v = parts[0].replace(/\D/g, '')
      const dec = parts[1]
      const calcNum = Number(dec !== undefined ? v + '.' + dec : v)
      let n = new Intl.NumberFormat('en-EN').format(v)
      n = dec !== undefined ? n + '.' + dec : n
      this.options_cash.cashInAmtUnit = n
    },
    'options_cash.cashInAmtMin' () {
      const parts = this.options_cash.cashInAmtMin.split('.')
      const v = parts[0].replace(/\D/g, '')
      const dec = parts[1]
      const calcNum = Number(dec !== undefined ? v + '.' + dec : v)
      let n = new Intl.NumberFormat('en-EN').format(v)
      n = dec !== undefined ? n + '.' + dec : n
      this.options_cash.cashInAmtMin = n
    },
    'options_cash.cashInAmtLimit' () {
      const parts = this.options_cash.cashInAmtLimit.split('.')
      const v = parts[0].replace(/\D/g, '')
      const dec = parts[1]
      const calcNum = Number(dec !== undefined ? v + '.' + dec : v)
      let n = new Intl.NumberFormat('en-EN').format(v)
      n = dec !== undefined ? n + '.' + dec : n
      this.options_cash.cashInAmtLimit = n
    },
    'options_cash.maxWinAmt' () {
      const parts = this.options_cash.maxWinAmt.split('.')
      const v = parts[0].replace(/\D/g, '')
      const dec = parts[1]
      const calcNum = Number(dec !== undefined ? v + '.' + dec : v)
      let n = new Intl.NumberFormat('en-EN').format(v)
      n = dec !== undefined ? n + '.' + dec : n
      this.options_cash.maxWinAmt = n
    },
    options_etc: {
      deep: true,
      handler (target) {
        this.feeRateMy = 100 - target.supFeeRate
      }
    }
  },
  methods: {
    thousand,
    async onSaveMaxWinAmt () {
      if (confirm('저장하시겠습니까?')) {
        const req = {
          maxWinAmt: this.options_cash.maxWinAmt.replace(/,/g, '')
        }
        const siteInfo = this.getSiteInfo()
        if (siteInfo) {
          req.siteId = siteInfo.siteId
        }
        console.log(req)
        const res = await siteOptionSave(req)
        if (res.resultCode === '0') {
          alert('설정 저장 완료')
        } else {
          alert('설정 저장 실패, 다시 시도해주세요.')
        }
      }
    },
    onChangeSiteCheck (value, e) {
      if (!this.options_site.siteCheckYn) {
        if (confirm('점검을 즉시 종료하겠습니까?')) {
          siteCheckOff({}).then(res => {
            if (res.resultCode === '0') {
              alert('정상적으로 저장되었습니다.')
            }
          })
        } else {
          this.options_site.siteCheckYn = true
        }
      } else {
        if (confirm('점검을 즉시 시작하겠습니까?')) {
          const params = {
            isEmergency: 'Y',
            checkType: 'site'
          }
          settingCheckInsert(params).then(res => {
            if (res.resultCode === '0') {
              alert('정상적으로 저장되었습니다.')
            }
          })
        } else {
          this.options_site.siteCheckYn = false
        }
      }
    },
    getParse () {
      const params = {
        parseUseYn: ''
      }
      parseApi(params).then(res => {
        console.log(res)
        if (res.resultCode === '0') {
          this.options_parse.parseUseYn = res.data.currentUseYn === 'Y'
        }
      })
    },
    onChangeParse (value, e) {
      if (!this.options_parse.parseUseYn) {
        if (confirm('개발자 모드를 사용안하겠습니까?')) {
          const params = {
            parseUseYn: 'N'
          }
          parseApi(params).then(res => {
            if (res.resultCode === '0') {
              alert('정상적으로 저장되었습니다.')
            }
          })
        } else {
          this.options_parse.parseUseYn = true
        }
      } else {
        if (confirm('개발자 모드를 사용하겠습니까?')) {
          const params = {
            parseUseYn: 'Y'
          }
          parseApi(params).then(res => {
            if (res.resultCode === '0') {
              alert('정상적으로 저장되었습니다.')
            }
          })
        } else {
          this.options_parse.parseUseYn = false
        }
      }
    },
    setOptionsYn (item, key) {
      item[key] = !item[key]
    },
    onChangeType (currentTab1) {
      this.currentTab1 = currentTab1
    },
    onChangeTypeSNS (currentTabSNS) {
      this.currentTabSNS = currentTabSNS
    },
    async getSiteOptions () {
      this.emitter.emit('Loading', true)
      const req = { }
      const siteInfo = this.getSiteInfo()
      if (siteInfo) {
        req.siteId = siteInfo.siteId
      }
      const _res = await siteOption(req)
      console.log(_res)
      const res = _res.data.option

      for (const key in res) {
        if (res[key] === 'Y') {
          res[key] = true
        }
        if (res[key] === 'N') {
          res[key] = false
        }
      }
      for (const key in this.options_site) {
        if (res[key]) {
          this.options_site[key] = res[key]
        }
      }
      for (const key in this.options_siteMember) {
        if (res[key]) {
          this.options_siteMember[key] = res[key]
        }
      }
      for (const key in this.options_basic) {
        if (res[key]) {
          this.options_basic[key] = res[key]
        }
      }
      for (const key in this.options_etc) {
        if (key !== 'sns') {
          if (res[key]) {
            this.options_etc[key] = res[key]
          }
        }
      }
      for (const key in this.options_cash) {
        if (res[key]) {
          this.options_cash[key] = res[key]
        }
      }
      // console.log(res);
      for (const key in this.options_bet) {
        // console.log(key);
        // console.log(res.hasOwnProperty(key));
        if (res[key]) {
          this.options_bet[key] = res[key]
        }
      }
      console.log('end?')
      this.emitter.emit('Loading', false)
    },
    async save_siteMember () {
      let req = lodash.cloneDeep(this.options_siteMember)
      req = this.saveDataConvertor(req)
      const siteInfo = this.getSiteInfo()
      if (siteInfo) {
        req.siteId = siteInfo.siteId
      }
      const res = await siteOptionSave(req)
      if (res.resultCode === '0') {
        alert('설정 저장 완료')
      } else {
        alert('설정 저장 실패, 다시 시도해주세요.')
      }
    },
    async save_cash () {
      let req = lodash.cloneDeep(this.options_cash)
      req = this.saveDataConvertor(req)
      const siteInfo = this.getSiteInfo()
      if (siteInfo) {
        req.siteId = siteInfo.siteId
      }
      const res = await siteOptionSave(req)
      if (res.resultCode === '0') {
        alert('설정 저장 완료')
      } else {
        alert('설정 저장 실패, 다시 시도해주세요.')
      }
    },
    async save_bet () {
      let req = lodash.cloneDeep(this.options_bet)
      req = this.saveDataConvertor(req)
      const siteInfo = this.getSiteInfo()
      if (siteInfo) {
        req.siteId = siteInfo.siteId
      }
      const res = await siteOptionSave(req)
      if (res.resultCode === '0') {
        alert('설정 저장 완료')
      } else {
        alert('설정 저장 실패, 다시 시도해주세요.')
      }
    },
    async save_basic () {
      let req = lodash.cloneDeep(this.options_basic)
      req = this.saveDataConvertor(req)
      const siteInfo = this.getSiteInfo()
      if (siteInfo) {
        req.siteId = siteInfo.siteId
      }
      const res = await siteOptionSave(req)
      if (res.resultCode === '0') {
        alert('설정 저장 완료')
      } else {
        alert('설정 저장 실패, 다시 시도해주세요.')
      }
    },
    async save_etc () {
      let req = lodash.cloneDeep(this.options_etc)
      req = this.saveDataConvertor(req)
      const siteInfo = this.getSiteInfo()
      if (siteInfo) {
        req.siteId = siteInfo.siteId
      }
      const res = await siteOptionSave(req)
      if (res.resultCode === '0') {
        alert('설정 저장 완료')
      } else {
        alert('설정 저장 실패, 다시 시도해주세요.')
      }
    },
    getSiteInfo () {
      const siteInfoCookie = getSiteData()
      if (siteInfoCookie) {
        const siteInfo = JSON.parse(siteInfoCookie)
        return siteInfo
      }
    },
    async getCompanyDetail () {
      const req = {
        siteId: this.siteInfo.siteId,
        memId: this.siteInfo.siteId
      }
      const getCompanyDetail = await companyDetail(req)
      if (getCompanyDetail) {
        const siteInfo = getCompanyDetail.data.memberDetail
        return siteInfo
      }
    },
    getDataConvertor (target) {
      const _target = target
      for (const item in _target) {
        if (typeof _target[item] === 'boolean') {
          if (_target[item]) {
            _target[item] = 'Y'
          } else {
            _target[item] = 'N'
          }
        }
      }
      return _target
    },
    saveDataConvertor (target) {
      const _target = target
      for (const item in _target) {
        if (item.indexOf('Amt') > -1) {
          _target[item] = _target[item].replace(/,/g, '')
        }
        if (typeof _target[item] === 'boolean') {
          if (_target[item]) {
            _target[item] = 'Y'
          } else {
            _target[item] = 'N'
          }
        }
      }
      return _target
    }
  },
  async created () {
    this.siteInfo = await this.getSiteInfo()
    // this.companyData = await this.getCompanyDetail()
    await this.getSiteOptions()
    await this.getParse()
  }
}
</script>

<style scoped>
.mt-14 {margin-top: 14px;}
.mt-52 {margin-top: 52px;}
.mt-62 {margin-top: 62px;}
.tab-wrap {box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);border-color: #97bab8;}
.tab-item.active,
.tab-item:hover:not(.active) {background: #97bab8;text-shadow: 0 1px 1px #215170;}
.d-flex-sb-c {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.btn-layout {
  padding: 4px 9px;
  background-color: #fff9f0;
  color: #3d3d3d;
}
table {
  width: 100%;
}
.page-container {
  padding: 20px 50px;
}
.content-wrap {
  margin-bottom: 40px;
}
.content {
}
.wrapper-contents{
  flex-direction: column;
}
.content td {
  padding: 4px 10px;
  height: 35px;
  box-sizing: border-box;
  border-bottom: solid 1px #ddd;
}
.input-title {
  font-weight: 700;
  text-align: left;
  width: 9%;
}
.input-value {
  width: 20%;
}
.inner {
  display: flex;
  align-items: center;
}
.mainId {
  margin-right: 5px;
}
.innerWrap {
  padding: 4px 10px;
  height: 35px;
  max-height: 35px;
  box-sizing: border-box;
  display: flex;
  align-content: center;
  border-bottom: solid 1px #ddd;
}
/* 라디오 */
.box-ui-radio {
  font-size: 1em;
  display: flex;
  justify-content: center;
  margin-right: 10em;
}
.box-ui-radio:last-child {
  margin-right: 0;
}
input[type="radio"] {
  display: none;
}
input[type="radio"] + label {
  display: flex;
  align-items: center;
}
input[type="radio"] + label > .circle {
  display: block;
  width: 17px;
  height: 17px;
  box-sizing: border-box;
  border: 2px solid #394357;
  border-radius: 100%;
  margin-right: 5px;
}
input[type="radio"]:checked + label > .circle {
  border: 5px solid #394357;
}

/* 체크박스 */
.box-ui-check {
  font-size: 1em;
  display: flex;
  justify-content: center;
}
input[type="checkBox"] {
  display: none;
}
input[type="checkbox"] + label {
  display: flex;
  align-items: center;
  margin-right: 10px;
}
input[type="checkbox"] + label > i {
  border: 2px solid #394357;
  background: #fff;
  border-radius: 5px;
  padding: 2px;
  font-size: 10px;
  color: #fff;
  margin-right: 4px;
}
input[type="checkbox"]:checked + label > i {
  background: #394357;
}

.hidenContent {
  display: none;
  align-items: center;
  width: fit-content;
}
.hidenContent.show {
  display: flex;
}
.subContent-wrapper {
  width: 100%;
  height: auto;
  max-height: unset;
  padding: 2px 5px;
}
.subContent-wrap {
  width: 100%;
}
.subContent-wrap .subTitle {
  font-size: 1.2em;
  font-weight: 800;
  background: #e9a198;
  color: #fff;
  padding: 8px 10px;
  position: relative;
}
.subContent {
  max-height: 0;
  overflow: hidden;
}
.subContent.open {
  padding: 5px 5px;
  max-height: unset;
}
.subContent th,
.subContent td {
  border: 1px solid #fff;
  padding: 4px 10px;
  height: 35px;
  box-sizing: border-box;
}
.subContent th {
  background: #ffe5de;
  color: #000;
  font-weight: 700;
}
th.subTitleHead {
  background: transparent;
  color: #000;
  text-align: left;
  border-bottom: solid 1px #ddd;
}
.subTable-title {
  background: #ffe5de;
  font-weight: 800;
  padding: 8px 8px;
}
.btn-subConShow {
  position: absolute;
  border: 2px solid #df4946;
  border-radius: 3px;
  padding: 4px;
  background: #df4946;
  color: #fff;
  font-size: 0.8em;
  transform: translateY(-50%);
  top: 50%;
  right: 8px;
  cursor: pointer;
}
.btn-subConShow:hover {
  background: #fff;
  color: #df4946;
}
.textCenter {
  text-align: center;
  font-weight: 700;
  padding: 4px 0;
}
.remark {
  font-weight: 800;
  color: red;
}
.notice {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  color: red;
}
.notice i {
  font-weight: 800;
  font-size: 1.5em;
  margin-right: 10px;
}
.notice > span {
  font-size: 1em;
  font-weight: 800;
}
/* title select */
.items .item:first-child {
  margin-right: 20px;
}
.item i {
  font-size: 14px;
  margin-right: 4px;
  color: #686868;
}
.total-info-wrap .item {
  display: flex;
  align-items: center;
}
.total-info-wrap .item img {
  margin: 0 10px 0 10px;
}
.title-select-wrap {
  font-size: 12px;
  margin: 5px;
  min-width: 75px;
  max-width: 75px;
  display: flex;
  flex-direction: column;
  border: 1px solid #afafaf;
}
.title-select-title {
  padding: 5px 4px;
  box-sizing: border-box;
  text-align: center;
  background: #db504d;
  color: #fff;
  font-weight: 700;
  border-bottom: 1px solid #afafaf;
}
.title-select-item {
  box-sizing: border-box;
  padding: 4px;
  font-size: 1em;
  width: 100%;
  border: 0;
}
.item-content input {
  width: 110px;
  border: solid 1px #808080;
  background: #fff;
}
.item-content select {
  border: 1px solid #808080;
  width: 110px;
  background: #fff;
}
.userInfo-select {
  font-weight: 500;
  padding: 2px 5px;
  height: 21px;
  width: 100%;
}
.s-content:first-child {border-top: 1px solid #d8d8d8;}
.s-content {
  width: 235px;
  border: 1px solid #d8d8d8;
  border-top: 0;
  min-height: auto;
  flex-basis: auto;
  margin: 0;
  padding: 12px 13px 12px 8px;
  box-sizing: border-box;
}
.s-content > .content {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #585963;
  justify-content: space-between;
}
.s-content > .content.f-as {
  align-items: flex-start;
  gap: 8px;
}
.s-content-wrap {
  max-height: fit-content;
  overflow-y: auto;
}
.s-content .content .dot-content select{
  width: 100%;
  background: #fff;
  color: #808080;
  padding: 0 13px;
}
.s-content > .title {
  margin-bottom: 0;
  background: #18698b;
  white-space: nowrap;
  color: #fff;
  border-radius: 6px 6px 0 0;
}
.page-content {box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);background-color: #fff;}
.itemz .s-content {
  flex: 0 0 9.6%;
}
.itemz .s-content .content select {
  border-top: 0;
  border-radius: 0 0 6px 6px;
  height: 30px;
  text-align: center;
}
.set-switch:after {
  display: none;
}
.rollcount {
  display: flex;
  align-items: center;
  justify-content: space-around;
  border: 1px solid #9e9e9e;
  border-top: 0;
  border-radius: 0 0 6px 6px;
}
.rollcount button {
  color: #676767;
  font-weight: bold;
  font-size: 1.2em;
  background: #fff;
}
.rollcount span {
  font-weight: bold;
  color: #212121;
  font-size: 1em;
}
textarea {
  width: 100%;
  min-height: 160px;
  border: 1px solid #18698b;
  resize: none;
  box-sizing: border-box;
  padding: 10px;
  border-radius: 3px;
}
.textarea-container {
  display: flex;
  align-items: stretch;
  height: calc(100% - 44px);
}
.center button {
  margin: 10px 0 70px;
  font-size: 14px;
  width: 130px;
  height: 45px;
}
.center {
  text-align: center;
}
.rollcount > input[type="number"] {
  width: 45px;
  text-align: center;
}
.item-row-container{
  flex-direction: column;
  align-items: flex-start;
}
.item-row-container .item-wrap > div {
  padding-left: 0;
}
.item-wrap:first-child {border-top: 1px solid #d8d8d8;}
.item-wrap {
  width: 235px;
  border: 1px solid #d8d8d8;
  border-top: 0;
  min-height: auto;
  margin: 0;
  padding: 12px 8px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.item-wrap > div.item-title {
   font-size: 12px;
   color: #585963;
   flex: 0 1 auto;
}
.item-wrap > div.item-content {
  flex: 0 1 auto;
}
.s-content .content > * {
  flex: unset;
}
.s-content .content > .dot-content {
  width: 100%;
}
.s-content > .content .itemtxt {
  display: flex;
  align-items: center;
}
.s-content > .content > .toggle-wrap {
  width: auto;
}
.page-contents {display: flex;gap: 50px;}
.page-contents-wrap {
  margin-bottom: 0;
  border-top: 0;
  border-bottom: 0;
}
.sub-title {
  font-size: 15px;
  font-weight: bold;
  color: #3d3d3d;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px 15px 14px;
}
.sub-title > h3 {
    display: flex;
    height: 100%;
    align-items: center;
}
.sub-title > .sub-title-wrap {
  display: flex;
  height: 100%;
  align-items: center;
  width: 180px;
  font-size: 0.9em;
  border-start-end-radius: 10px;
  border-start-start-radius: 10px;
}
.item-row-container:first-child {
  border-top: 0;
}
.toggle-wrap {
  padding: 0;
}
.recom {display: flex; width: 67%;}
.tab-item:first-child {width: 33.33%;}
.tab-item {width: calc(33.33% + 10px);font-size: 13px;}
</style>
