<template>
  <div class="toggle-wrap">
    <label class="toggle-text" v-if="textType" :class="[textType,{on:this.checked}]"></label>
    <input type="checkbox" class="set-switch" :checked="checked" @change="onChange" />
  </div>
</template>

<script>
export default {
  name: 'UiToggle',
  props: ['checked', 'textType'],
  emits: ['setYn'],
  methods: {
    onChange (e) {
      this.$emit('setYn', this.checked)
    }
  }
}
</script>

<style scoped>
.set-switch-wrap {
  display: flex;
  border-bottom: solid 1px #ddd;
  padding-bottom: 7px;
  padding-top: 7px;
}
input[type="checkbox"].set-switch {
  display: flex;
  align-items: center;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  outline: 0;
  cursor: pointer;
  width: 35px;
  height: 15px;
  border-radius: 3em;
  box-shadow: 3px 3px 6px 0 rgba(0, 0, 0, 0.1) inset;
  border: solid 1px #b0b0b0;
  box-sizing: border-box;
  background-color: #eee;
  transition: background-color 0.15s ease-in-out;
  position: relative;
  max-width: 40px;
}

.set-switch:after {
  /* margin-left: 2px; */
  position: absolute;
  /* display: block; */
  content: "";
  left: 0;
  width: 13px;
  height: 13px;
  background: #fff;
  border-radius: 100%;
  top: 50%;
  transform: translateY(-50%);
  transition: left 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275), padding 0.3s ease, margin 0.3s ease;
  box-shadow: 2px 1px 5px 0px rgb(0 0 0 / 41%);
}
input[type="checkbox"].set-switch:checked {
  background: #15e41c;
}
input[type="checkbox"].set-switch:checked:after {
  left: calc(100% - 13px);
}
.set-switch-text {
  font-weight: 400;
  color: #cc1010;
  /* transition: .5s; */
}
input[type="checkbox"].set-switch:checked + .set-switch-text {
  font-weight: 800;
  color: #23af23;
}

.toggle-wrap {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 4px;
  box-sizing: border-box;
}
.toggle-wrap.dp-inb {
  display:inline-block;
}
.toggle-text {
  text-align: center;
  font-weight: 800;
  color: #ca1717;
  width: 50px;
  display: none;
}
.toggle-text.on{
  color: #0c960c;
}
.toggle-text.default.on:after {
  content: "ON";
}
.toggle-text.default:after {
  content: "OFF";
}
.toggle-text.auto.on:after {
  content: "자동";
}
.toggle-text.auto:after {
  content: "수동";
}
.items .toggle-text {
  display: none;
}
</style>
